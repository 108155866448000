require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// import $ from 'jquery';
window.$ = window.jQuery = $;

// require('jquery');

require('packs/bootstrap');
// import 'bootstrap/dist/css/bootstrap.min.css';


// // import 'slick-carousel';

require('datatables.net');

require("datatables.net"); //(window, $);
require("datatables.net-responsive"); //(window, $);

import "datatables.net-bs/css/dataTables.bootstrap.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";


// // import 'slick-carousel/slick/slick';
// // require('slick-carousel/slick/slick');

const moment = require('moment');
window.moment = moment;

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;
import 'flatpickr/dist/flatpickr.css';


import '@client-side-validations/client-side-validations'

import 'packs/custom_style.css';

// // Datatables Editor
// // require('datatables.net-editor/js/dataTables.editor.js')
// // require('datatables.net-editor-bs4/js/editor.bootstrap4.js')
// // require('datatables.net-editor-bs4/css/editor.bootstrap4.css').



// // require('datatables.net-select')(window, $)
require('packs/slick');


// require('datatables.net-bs4')();
// var DataTable = require('datatables.net')(window, $);
// window.DataTable = DataTable;


// $(document).ready(function() {
//     $('.slick11Prep').slick({
//         centerMode: true,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         dots: true,
//         infinite: true,
//         speed: 300,
//         autoplaySpeed: 1000,
//         fade: true,
//         cssEase: 'linear',
//     });
// });